// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import flatpickr from "flatpickr/dist/flatpickr";
import "flatpickr/dist/l10n/fr";

Rails.start()
ActiveStorage.start()

window.Rails = Rails;
window.flatpickr = flatpickr;
window.removeElt = function(elt) {
    if (elt) {
        elt.parentElement.removeChild(elt);
    }
}
window.displayAlerts = function() {
    var alerts = document.querySelectorAll("#notice, #alert");
    if (alerts.length > 0) {
        var validationMsg = document.querySelector(".validation_markup");
        if (validationMsg) {
            validationMsg.scrollIntoView({
                behavior: 'smooth'
            });
        }
        setTimeout(function() {
            alerts[0].style = "transition: 1s; opacity: 0;";
            setTimeout(function() {
                alerts[0].style = "display: none;";
            }, 1000);
        }, 5000);
    }
}
